import React from "react";
import Layout from "../../../components/Layout";

const TermsOfServicePage = () => {
  const pageTitle = "Terms and Service | Unschool";
  const pageDescription =
    "The Company ensures steady commitment to Your usage of the Platform and privacy with regard to the protection of Your invaluable information.";

  return (
    <Layout title={pageTitle} description={pageDescription}>
      <div className="policies-container">
        <h2 className="title" style={{ marginBottom: "20px" }}>
          TERMS OF SERVICE
        </h2>
        <div
          dangerouslySetInnerHTML={{
            __html: `
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Welcome to Unschool, an online learning community to master
real-world skills. The Unschool website and service including
(without limitation) all websites, mobile applications and other
interactive properties through which the service is delivered
(collectively, the &ldquo;Service&rdquo;) are owned, operated and
distributed by Edupolis Technologies Inc., a Delaware corporation
(referred to in these Terms of Service as &ldquo;Unschool&rdquo; or
&ldquo;we&rdquo; and through similar words such as &ldquo;us,&rdquo;
&ldquo;our,&rdquo; etc.). By accessing any part of the Service, you
are agreeing to the terms and conditions described below
(&ldquo;these Terms of Service&rdquo;), the terms and conditions of
our privacy policy (the &ldquo;Privacy Policy&rdquo;) and any other
legal notices published by us on the Service (collectively, the
&ldquo;Governing Documents&rdquo;). If you do not agree to any of
these terms, you should not use the Service. These Terms of Service
apply to all users, including both users who are simply viewing
content available via the Service and users who have registered as
members of Unschool. If you are a business to business purchaser or
partner of Unschool services, your access and use of the Service are
additionally subject to but not restricted to the Master Services
Agreement.&nbsp;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We may, at our sole discretion, modify these Terms of Service at any
time. Registered users will be notified in their registered email
with the updated Terms of Service. &nbsp;By accessing the Service at
any time after such modifications, you are agreeing to such
modifications. These Terms of Service were last modified as of
[Date].
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Description of Service
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
The Unschool Service allows students to find courses that they are
interested in taking and allows coaches, instructors, teachers, and
industry experts (both part and full-time) to host their courses and
find students interested in taking their courses.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We reserve the right to refuse to provide the Service to any person
for any reason and/or to discontinue the Service in whole or in part
at any time, with or without prior notice.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Copyright, Licenses and User Submissions
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
The entire contents of the Service are protected by international
and national copyright and trademark laws and other proprietary
rights relevant to your jurisdiction. The owners of the copyrights
and trademarks are Unschool, its affiliates and/or other third party
licensors. YOU MAY NOT MODIFY, COPY, REPRODUCE, REPUBLISH, UPLOAD,
POST, TRANSMIT, OR DISTRIBUTE, IN ANY MANNER, THE MATERIAL ON THE
SERVICE, INCLUDING TEXT, GRAPHICS, CODE AND/OR SOFTWARE. You may
print and download portions of material from the different areas of
the Service solely for your own non-commercial use provided that you
agree not to change or delete any copyright or proprietary notices
from the materials, and not to share any material that you paid for
with anyone else who has not also purchased a license for that
material.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You may not use any robots, spiders, or similar data mining, data
gathering or extraction tools or manual processes to collect, gather
or copy any content or data on or related to the Service in a manner
not authorized by Unschool in writing. You may not engage in
practices of &ldquo;screen scraping,&rdquo; &ldquo;database
scraping&rdquo; or any other practice or activity the purpose of
which is to obtain lists of users, portions of a database, or other
lists or information from the Service, in any manner and any
quantities not authorized by Unschool in writing. You may not frame
or utilize framing techniques to enclose any trademark, logo or
other proprietary information (including images, text, page layout
or form) of Unschool or its affiliates without express written
consent. You may not use meta tags or any other &ldquo;hidden
text&rdquo; utilizing the Unschool name or trademarks without the
express written consent of Unschool.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Content submitted by users for inclusion on the Service (including,
without limitation, any information submitted on the community,
discussion boards, forums or other public areas of the Service) is
sometimes referred to in these Terms of Service as &ldquo;User
Submissions.&rdquo; Whether or not any User Submission is published,
it will be subject to these Terms of Service. Unschool does not
guarantee any confidentiality with respect to a User Submission,
regardless of whether or not it is published. You are solely
responsible for your own User Submissions and the consequences of
posting or publishing them. You represent and warrant that you own
or have the necessary licenses, rights, consents and permissions to
your User Submissions (and all content included therein), including
the right to authorize Unschool to use the User Submissions in the
manner contemplated by the Service and these Terms of Service.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You hereby grant to Unschool a non-exclusive, royalty-free,
worldwide, perpetual license, with the right to sub-license, to
reproduce, distribute, transmit, create derivative works of,
publicly display and publicly perform any User Submissions or any
other materials or information (including, without limitation, ideas
for new or improved products or services) you communicate to
Unschool by all means and in any media now known or hereafter
developed. You also grant to Unschool the right to use your name in
connection with the submitted materials and other information as
well as in connection with all advertising, marketing and
promotional material related thereto. You agree that you shall have
no recourse against Unschool for any alleged or actual infringement
or misappropriation of any proprietary right in your communications
to Unschool.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We neither endorse nor assume any liability for the contents of any
User Submission. We generally do not pre-screen, monitor, or edit
User Submissions. However, we and our agents have the right at their
sole discretion to remove any User Submission or other content that,
in our judgment, does not comply with these Terms of Service and any
other rules of user conduct for the Service, or is otherwise
harmful, objectionable, or inaccurate. We are not responsible for
any failure or delay in removing any such User Submission or other
content. You hereby consent to such removal and waive any claim
against us arising out of such removal of any User Submission,
whether it is your own or another user&rsquo;s. See &ldquo;If You
See Something That Infringes Your Copyright&rdquo; below for a
description of the procedures to be followed in the event that any
party believes that a User Submission infringes on any patent,
trademark, trade secret, copyright, right of publicity, or other
proprietary rights of any party.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
If You See Something That Infringes Your Copyright
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
In accordance with the Digital Millennium Copyright Act
(&ldquo;DMCA&rdquo;), we&rsquo;ve adopted the following policy
toward copyright infringement. We reserve the right to (1) block
access to or remove material that we believe in good faith to be
copyrighted material that has been illegally copied and distributed
by any of our advertisers, affiliates, content providers, members or
users and (2) remove and discontinue service to repeat offenders.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Procedure for Reporting Copyright Infringements. If you believe that
material or content residing on or accessible through the Service
infringes your copyright (or the copyright of someone whom you are
authorized to act on behalf of), please send a notice of copyright
infringement containing the following information to
Unschool&rsquo;s Designated Agent to Receive Notification of Claimed
Infringement (our &ldquo;Designated Agent,&rdquo; whose contact
details are listed below):
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
A physical or electronic signature of a person authorized to act on
behalf of the owner of the copyright that has been allegedly
infringed;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Identification of works or materials being infringed;
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Identification of the material that is claimed to be infringing
including information regarding the location of the infringing
materials that the copyright owner seeks to have removed, with
sufficient detail so that Unschool is capable of finding and
verifying its existence;
</span>
</p>
<ol style="margin-bottom:0cm;margin-top:0cm;" start="1" type="a">
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Contact information about the notifier including address,
telephone number and, if available, email address;
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
A statement that the notifier has a good faith belief that the
material identified in (1)(c) is not authorized by the copyright
owner, its agent, or the law; and
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
A statement made under penalty of perjury that the information
provided is accurate and the notifying party is authorized to make
the complaint on behalf of the copyright owner.
</span>
</li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Once Proper Bona Fide Infringement Notification is Received by the
Designated Agent.&nbsp;
</span>
</p>
<ol style="margin-bottom:0cm;margin-top:0cm;" start="1" type="a">
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Upon receipt of a proper notice of copyright infringement, we
reserve the right to:
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
remove or disable access to the infringing material;
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
notify the content provider who is accused of infringement that we
have removed or disabled access to the applicable material; and
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
terminate such content provider&apos;s access to the Service if he
or she is a repeat offender.
</span>
</li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Procedure to Supply a Counter-Notice to the Designated Agent.
</span>
</strong>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
&nbsp;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:36.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
If the content provider believes that the material that was removed
(or to which access was disabled) is not infringing, or the content
provider believes that it has the right to post and use such
material from the copyright owner, the copyright owner&apos;s agent,
or, pursuant to the law, the content provider may send us a
counter-notice containing the following information to the
Designated Agent:
</span>
</p>
<ol style="margin-bottom:0cm;margin-top:0cm;" start="1" type="A">
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
A physical or electronic signature of the content provider;
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Identification of the material that has been removed or to which
access has been disabled and the location at which the material
appeared before it was removed or disabled;
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
A statement that the content provider has a good faith belief that
the material was removed or disabled as a result of mistake or
misidentification of the material; and
</span>
</li>
<li style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;color:#fff;text-align:justify;border:none;'>
<span style='font-size:16px;line-height:115%;font-family: "Open Sans", sans-serif;color:#fff;'>
Content provider&apos;s name, address, telephone number, and, if
available, email address, and a statement that such person or
entity consents to the jurisdiction of the Courts in which
Unschool is located, and that the content provider will accept
service of process from the person who provided notification of
the alleged infringement.
</span>
</li>
</ol>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;text-indent:18.0pt;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
If a counter-notice is received by the Designated Agent, Unschool
may, in its discretion, send a copy of the counter-notice to the
original complaining party informing that person that Unschool may
replace the removed material or cease disabling it in 10 business
days. Unless the copyright owner files an action seeking a court
order against the content provider accused of committing
infringement, the removed material may be replaced or access to it
restored in 10 to 14 business days or more after receipt of the
counter-notice, at Unschool&rsquo;s discretion.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Please contact Unschool&apos;s Designated Agent at [Email of
Designated Agent] . Alternatively, you may send a complete notice of
copy infringement to the following address:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
[Address of Unschool]
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Account Terms
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
The following terms apply to your use of the Service and any account
that you may open or attempt to open via the Service:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You represent and warrant that you are at least 18 years of age. If
you are under the age of 18, you may not, under any circumstances or
for any reason, use the Service without the supervision of a parent
or guardian.<strong>&nbsp;</strong>
</span>
<strong>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#333333;background:white;'>
&nbsp;
</span>
</strong>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Any assistance<strong>&nbsp;</strong>provided by a parent or
guardian to users under the age of 18 in accessing the services
requires the parent or guardian to take full responsibility for any
responsibilities.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You must be a human to open an account. Accounts registered by
&ldquo;bots&rdquo; or other automated methods are not permitted.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You are responsible for maintaining the security of your account,
password and One-Time Password(s) (OTP). We view any actions taken
by your account as taken by you. Unschool will not be liable for any
loss or damage from your failure to comply with this security
obligation.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Changing Fees
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We reserve the right, at any time, to change any fees or charges for
using any services provided on the Service (or to begin charging
fees for any free service), provided that such changes will not
apply to fees or charges paid or free services used prior to the
time of the change.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Rules for All Users
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool is a platform built for millions of learners. When you sign
up for the Site, you agree to the following rules:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will use the Service only for lawful purposes and agree to not
use the Service in any way that will infringe upon the use or rights
of any other user.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Your use of the Service is subject to all applicable laws and
regulations, and you are solely responsible for the substance of
your communications through the Service.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not upload any copyrighted, trademarked, or proprietary
materials on the Service without the expressed permission of the
owner. You will not post any content that infringes on any patent,
trademark, trade secret, copyright, right of publicity, or other
intellectual property or proprietary right of any party.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You understand that courses posted are publicly available to be
viewed and accessed by any student.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You agree that you will not engage in any behaviour that constitutes
unauthorized or unsolicited advertising, junk or bulk email (also
known as &ldquo;spamming&rdquo;), chain letters, any other form of
unauthorized solicitation, or any form of lottery or gambling.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not share content from the Service with anyone who is not
properly licensed to access the content.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not upload, share, post, distribute or otherwise partake in
any behaviour that is unlawful, threatening, abusive, harassing,
defamatory, libellous, deceptive, fraudulent, invasive of
another&rsquo;s privacy, tortious, contains explicit or graphic
descriptions or accounts of sexual acts (including but not limited
to sexual language of a violent or threatening nature directed at
another individual or group of individuals), or otherwise violates
our rules or policies.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not partake in any behaviour that victimizes, harasses,
degrades, or intimidates an individual or group of individuals on
the basis of religion, gender, sexual orientation, race, ethnicity,
age, or disability.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not share software viruses or any other computer code,
files, or programs that are designed or intended to disrupt, damage,
or limit the functioning of any software, hardware, or
telecommunications equipment or to damage or obtain unauthorized
access to any data or other information of any third party.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not impersonate any person or entity, including any of our
employees or representatives.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not use your account to breach the security of another
account or attempt to gain unauthorized access to another network or
server. Not all parts of the Service may be available to you or
other authorized users of the Service. You shall not interfere with
anyone else&rsquo;s use and enjoyment of the Service. Users who
violate systems or network security may incur criminal or civil
liability.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Specific Rules for Coaches
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
When you publish a course on the Site, you agree to the following
rules:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will honour any enrollments through unschool.in at the price and
time listed in the Service, and not refuse enrollments or
participation by any student for any discriminatory or other illegal
reason.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You grant us the right to place advertisements on your content at
our sole discretion.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Any content you submit to the Service will not contain third party
copyrighted material or material that is subject to other
third-party proprietary rights, unless you have permission from the
rightful owner of the material.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You represent and warrant to Unschool that you are qualified to
teach the classes that you list with the Service and that you will
at all times conduct yourself in a professional manner and in
compliance with all applicable laws.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You understand that, if you teach a course, your students will have
the ability to post a review of this course. We cannot control the
contents of any such review and will not be held responsible for any
information or opinions that a user may include in any such review.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You may choose to remove content from your course and will continue
to own such content (as mentioned in the Service Level Agreement),
but we will continue to have the right to use that content and to
provide it to students who have paid for or enrolled in your class.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You grant us full ownership of any video content that we have filmed
for you. It is at our sole discretion to remove, add, or edit that
video content.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You agree that you will not submit content to Unschool that violates
the Unschool Community and Course Guidelines, which may be
occasionally updated &nbsp;(as mentioned in the Service Level
Agreement).
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool reserves the right to but is under no obligation, to delete
the listing of any course at any time and for any reason.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
These rules shall be read as a part and parcel of the Service Level
executed between the Unschool and the Coaches.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Specific Rules for Users
</span>
</u>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
When you enroll in a class on the Site, you agree to the following
rules:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will accept all of the terms and conditions including the
cancellation policy listed in the Service when you agree to enroll
in or access a class on Unschool.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will follow all reasonable rules set by a Coach with respect to
a class, and you will not take any actions to interfere with the
Coach or any other students in the class.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You may access class content for your information and personal use
only.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You will not copy any materials or techniques for purposes of your
own or others&rsquo; classes, or for interfering with any class or
the Coach&rsquo;s ongoing relationship with any students
participating in such class.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool reserves the right to delete or suspend your account if you
violate any of our rules, or at any time and for any reason.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Data Policy
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
When a student takes a class on Unschool, we will share the
student&rsquo;s username and profile with the Coach for the purpose
of teaching these students on Unschool. By enrolling in a class on
Unschool, a student grants to us permission to share that
student&rsquo;s information with the Coach. The Coach is required to
respect the privacy of the student. The Coach has a limited license
to use this information only for Unschool-related communications or
for Unschool-facilitated transactions. The Coach may use that
personal information for no other purpose, such as sending
unsolicited commercial messages or unauthorized transactions.
Without express consent from that person, you must not misuse any
student&rsquo;s personal information. Remember that you need to
comply with all relevant legal rules when you use Unschool. This
include applicable data protection and privacy laws which govern the
ways in which you can use another Unschool user&rsquo;s information.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Finally, we may collect demographic information about you, such as
gender, race and ethnic origin. We usually collect and use this
information on an anonymized basis. If you provide demographic
information to Unschool, you do so voluntarily and Unschool will
collect, store and use your demographic information in accordance
with our privacy policy. We may use this type of data to analyze
trends and assist us in understanding demographic patterns related
to our community and new and existing products, programs, or
services; to administer, analyze, and improve the content and
functionality of the Site and Services; for marketing; and to gather
demographic information about our user base as a whole. We utilize
unstructured machine learning technologies to understand user
behaviors and to provide user-specific recommendations and other
personalization of the Site or Services experience.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Teaching or Participating in a Class
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
These Terms of Service and the Governing Documents are in addition
to any agreements, releases or other documents (the &ldquo;Class
Agreements&rdquo;) that a Coach and student may enter into in
connection with a class, including any Class Agreements entered into
through the Service. Students and Coachs are each responsible to the
other for complying with the terms of the Class Agreements, but
Unschool has no control over either party&rsquo;s compliance. Among
other things, Unschool cannot control (a) the provisions of the
Class Agreements, (b) the accuracy or legality of the Class
Agreements or (c) any party&rsquo;s performance of its obligations
under the Class Agreements. Accordingly, Unschool shall not be held
responsible for any liability arising out of the Class Agreements or
any actions that you may take in reliance thereon, and is not a
party to any transaction between you and any other user.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE UNSCHOOL
AND ITS OFFICERS, DIRECTORS, EMPLOYEES, AGENTS, AFFILIATES AND THEIR
RESPECTIVE SUCCESSORS AND ASSIGNS (COLLECTIVELY, THE &ldquo;UNSCHOOL
PARTIES&rdquo;) FROM ANY CLAIMS OR DAMAGES OF ANY KIND OR NATURE,
KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED, DISCLOSED OR
UNDISCLOSED, RELATING TO ANY DISPUTE RELATING TO THE CLASS
AGREEMENTS OR ANY TRANSACTION OR ATTEMPTED TRANSACTION WITH ANOTHER
USER OF THE SERVICE (WHETHER A STUDENT OR Coach). YOU AGREE AND
UNDERSTAND AND INTEND THAT THIS ASSUMPTION OF RISK AND RELEASE IS
BINDING UPON YOU AND YOUR HEIRS, EXECUTORS, AGENTS, ADMINISTRATORS
AND ASSIGNS.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Your teaching or participation in a class is at your sole risk.
Unschool does not supervise classes and is not involved in any way
with the actions of any individuals (whether students or Coachs) at
any class. As a result, we have no control over the identity or
actions of the individuals who are present at these classes, and we
request that our users exercise caution and good judgment when
participating in Classes. You acknowledge and agree that by
participating in any class (whether as a student or Coach) you may
be exposed to a variety of risks and hazards, which may or may not
be foreseen, including (without limitation) personal injury, breach
of privacy and damage to reputation. You are solely responsible for
all costs and/or risks associated with your participation in any
class.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
TO THE FULLEST EXTENT PERMITTED BY LAW, YOU HEREBY RELEASE UNSCHOOL
AND EACH OF THE OTHER UNSCHOOL PARTIES FROM ANY CLAIMS OR DAMAGES OF
ANY KIND OR NATURE, KNOWN OR UNKNOWN, SUSPECTED OR UNSUSPECTED,
DISCLOSED OR UNDISCLOSED, RELATING TO YOUR PARTICIPATION IN ANY
EVENT, WHETHER AS A STUDENT OR Coach. YOU AGREE AND UNDERSTAND AND
INTEND THAT THIS ASSUMPTION OF RISK AND RELEASE IS BINDING UPON YOU
AND YOUR HEIRS, EXECUTORS, AGENTS, ADMINISTRATORS AND ASSIGNS.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Membership Fees
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Your subscription will continue and automatically renew on a
recurring basis corresponding to the term of your subscription
unless and until you cancel your subscription, or your account is
otherwise suspended or terminated pursuant to these Terms.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We will charge your Payment Method a periodic subscription fee on a
recurring basis corresponding to the term of your subscription, and
any applicable taxes. You are responsible for all applicable fees
and charges incurred, including applicable taxes.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool reserves the right to change the terms of your
subscription, including price, from time to time, effective as of
the beginning of your next billing period following the date of the
change. We will give you advance notice of these changes, but we
will not be able to notify you of changes in any applicable taxes.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool collects all fees and pays out all Coachs through third
party providers. Each provider is its own company, and Unschool
isn&rsquo;t responsible for its performance.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
No refunds are offered for membership fees or any other fees
collected by the Service.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Coach Payments
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;'>
The Remuneration for the Services offered by the Coachs shall be as
per the Annexures to the Service Level Agreement which shall be
entered into between the Unschool and the Coachs.&nbsp;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Coachs have 30 days from the time at which a payment was or should
have been made to report any discrepancies. After 30 days, you have
waived your right to dispute any payment.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool reserves the right to withhold or cancel payments to Coachs
if it deems, in its sole judgment and absolute discretion, that the
Coach engaged in behaviour that violated any part of this Terms of
Service and the Service Level Agreement .
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Referral Program
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
As a Coach on Unschool, you may participate in our &ldquo;Referral
Program&rdquo; by referring potential students to the Service. Our
Referral Program is governed by terms and conditions, which may be
found&nbsp;
</span>
<a href="https://help.skillshare.com/hc/articles/4568812162573">
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
here
</span>
</a>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Recurring Billing
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
The membership fee will be billed on the beginning of the paying
portion of your membership and each month or year thereafter unless
and until you cancel your membership. We automatically bill your
Payment Method each month on the calendar day corresponding to the
commencement of your paying membership. We reserve the right to
change the timing of our billing, in particular, as indicated below,
if your Payment Method has not successfully settled. In the event
your paying membership began on a day not contained in a given
month, we may bill your Payment Method on a day in the applicable
month or such other day as we deem appropriate. For example, if you
started your membership or became a paying member on March 31st,
your next payment date is likely to be April 30th, and your Payment
Method would be billed that date.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Refund Policy
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Memberships
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unless your subscription plan is offered with a 30-day money-back
guarantee, payments are non-refundable and there are no refunds or
credits for partially used periods, unless otherwise required by
applicable law. You may cancel your membership in accordance with
this Terms of Service.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
If you purchased an annual subscription plan offered with a 30 day
money back guarantee, you will be entitled to a full refund upon
cancellation by <u>contacting us</u> to request a refund. Once your
refund is confirmed, you will lose all access to your Unschool
membership. Unschool has no obligation to consider or grant refund
requests (i) for subscription plans purchased with a 7-day or longer
free trial; (ii) that do not occur within the first thirty (30) days
of your membership or (iii) if there has been a violation of our
Terms of Service or our Community Guidelines or fraud. You are
entitled to one refund only. After your refund, any future
subscriptions will no longer qualify for the 30-day money back
guarantee.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
No refunds will apply to subsequent renewals of an annual membership
or subscription purchased through the Apple iTunes Store or our
iPhone application, or the Google Play Store or our Android
application.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Job Program Courses
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
If you purchase a Job Program Course, you will not be eligible for
any refund until otherwise mentioned on the Income Sharing
Agreement. Refunds are only processed if the guidelines, completion
deadlines and other metrics of the program are met by the user. In
no other cases, will the refund be issued to the user.&nbsp;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
For Memberships, we reserve the right, at our sole discretion, to
limit or deny refund requests in cases where we believe there is a
violation of these Terms of Service, our Community Guidelines or any
other Unschool policy.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Cancellations
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You may cancel your Unschool membership at any time for any or no
reason. To cancel your recurring subscription, please visit your
payment settings within your Unschool account profile located
at&nbsp;
</span>
<a href="https://www.skillshare.com/settings/payments">
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
[
</span>
</a>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Website to cancel Subscription]
</span>
</u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
&nbsp;. If you have cancelled your Unschool membership, you will
lose access to all membership Classes you enrolled into during your
Unschool membership at the end of your billing period. If you choose
to sign back up as a paying member, your billing date will reset to
the day you paid.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Free Trials
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
From time to time, we or others on our behalf may offer trials of
paid subscriptions for our Unschool membership for a specified
period without payment or at a reduced rate (a &ldquo;Trial&rdquo;).
Unschool reserves the right, in its absolute discretion, to
determine your eligibility for a Trial, and, subject to applicable
laws, to withdraw or to modify a Trial at any time without prior
notice and with no liability, to the greatest extent permitted under
the law.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
For some trials, we will require you to provide your payment details
to start the Trial. At the end of such trials, we may automatically
start to charge you for the applicable paid subscription on the
first day following the end of the trial on a recurring monthly or
annual basis. When you provide a Payment Method, we will attempt to
verify the information you entered by processing an authorization
hold. We do not charge you in connection with this authorization
hold, but your available balance or credit limit may be reduced. By
providing your payment details in conjunction with the trial, you
agree to this charge using such payment details. If you do not want
this charge, you must cancel the applicable paid subscription
through your payment settings within your Unschool account profile
located at&nbsp;
</span>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;'>
[Website for the Profile]
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Price Changes
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We reserve the right to adjust pricing for our service, or any
components thereof in any manner and at any time as we may determine
in our sole and absolute discretion. Except as otherwise expressly
provided for in these Terms of Use, any price changes to your
service will take effect following email notice to you.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Student Referral Program
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You may participate in our &ldquo;Student Referral Program&rdquo; by
referring potential students to the Service. Under the Student
Referral Program, you will receive an award (a &ldquo;Student
Referral Award&rdquo;) of 1 free month of membership for each
Qualified Student Referral (as hereinafter defined) redeemable only
when you upgrade your Unschool account to a membership.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
In order to receive a Student Referral Award, each of the following
requirements must be met (any referral for which all such
requirements are met hereinafter referred to as a &ldquo;Qualified
Student Referral&rdquo;): (i) you must log in to your Unschool
account on the Service and select the &ldquo;Invite Friends&rdquo;
link; (ii) you must share the link provided with friends via
Facebook, Twitter or similar services, by copying and pasting the
Unique Referral Link generated via the Service, by sending the
Unique Referral Link in an email via the Services to your friends,
or by using the other tools provided by Unschool to share the Unique
Referral Link with friends; and (iii) a friend who clicks the Unique
Referral Link must create a Unschool account and successfully pay
for the first month of membership that is not refunded.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We reserve the right to suspend your account and remove referrals
should we notice any activity we determine as abuse of the referral
program.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool Rights
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool reserves these rights:
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We can make changes to the Unschool Site and Services without notice
or liability.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We have the right to terminate your membership, account, payment, or
other affiliation with the Service at any time and for any reason.
We can change our eligibility criteria at any time. If these things
are prohibited by law where you live, then we revoke your right to
use Unschool in that jurisdiction.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We will cooperate fully with investigations of violations of systems
or network security at other sites, including cooperating with law
enforcement authorities in investigating suspected criminal
violations.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We have the right to remove a Coach from the Partner Program at any
time, without prior notice, at our sole discretion, should we
identify that their account is associated with behavior that we deem
to be in violation of our rules or guidelines.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
We have the right to reject, cancel, interrupt, remove, or suspend
any class, comment, or other posted comment at any time and for any
reason.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Unschool is not liable for any damages as a result of any of these
actions, and it is our policy not to comment on the reasons for any
such action.:
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Disclaimer of Warranties
</span>
</u>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
THE SERVICE AND ALL MATERIALS INCLUDED THEREIN ARE PROVIDED ON AN
&ldquo;AS IS&rdquo; AND &ldquo;AS AVAILABLE&rdquo; BASIS WITHOUT
WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT
LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR
A PARTICULAR PURPOSE, OR THE WARRANTY OF NON-INFRINGEMENT. WITHOUT
LIMITING THE FOREGOING, WE MAKE NO WARRANTY THAT (A) THE SERVICE AND
MATERIALS WILL MEET YOUR REQUIREMENTS, (B) THE SERVICE AND MATERIALS
WILL BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE, (C) THE
RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE OR
MATERIALS WILL BE EFFECTIVE, ACCURATE OR RELIABLE, OR (D) THE
QUALITY OF ANY CLASS THAT YOU ATTEND VIA THE SERVICE, OR ANY OTHER
PRODUCTS, SERVICES, OR INFORMATION PURCHASED OR OBTAINED BY YOU FROM
THE SERVICE FROM US OR OUR AFFILIATES WILL MEET YOUR EXPECTATIONS OR
BE FREE FROM MISTAKES, ERRORS OR DEFECTS.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
THE SERVICE COULD INCLUDE TECHNICAL OR OTHER MISTAKES, INACCURACIES
OR TYPOGRAPHICAL ERRORS. WE MAY MAKE CHANGES TO THE SERVICE AND ANY
OF THE CONTENT OR FEATURES INCLUDED THEREIN, INCLUDING THE PRICES
AND ACCESS RULES FOR ANY FUNCTIONALITY, AT ANY TIME WITHOUT NOTICE.
THE CONTENT INCLUDED ON THE SERVICE MAY BE OUT OF DATE, AND WE MAKE
NO COMMITMENT TO UPDATE SUCH CONTENT.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
THE USE OF THE SERVICE OR THE DOWNLOADING OR OTHER ACQUISITION OF
ANY MATERIALS THROUGH THE SERVICE IS DONE AT YOUR OWN DISCRETION AND
RISK AND WITH YOUR AGREEMENT THAT YOU WILL BE SOLELY RESPONSIBLE FOR
ANY DAMAGE OR LOSS OF DATA THAT RESULTS FROM SUCH ACTIVITIES.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;Content available
through the Service or presented at any class represents the
opinions and judgments of an information provider, user, or other
person or entity not connected with us. We do not endorse, nor are
we responsible for the accuracy or reliability of, any opinion,
advice, or statement made by anyone other than an authorized
Unschool spokesperson speaking in his/her/their official capacity.
Please refer to the specific editorial policies posted on various
sections of the Service for further information, which policies are
incorporated by reference into these Terms of Service.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
The Classes offered on Unschool are offered directly by the Coachs.
Unschool is not responsible or liable for any information on its
Service listings or profiles. Unschool is not responsible for any
changes made to a class by the Coach.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You understand and agree that temporary interruptions of the Service
may occur as normal events. You further understand and agree that we
have no control over third party networks you may access in the
course of the use of the Service, and therefore, delays and
disruption of other network transmissions are completely beyond our
control.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You understand and agree that the Service is provided &ldquo;AS
IS&rdquo; and that we assume no responsibility for the timeliness,
deletion, mis-delivery or failure to store any user communications
or personalization settings.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Limitation of Liability
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
IN NO EVENT SHALL WE OR ANY OTHER UNSCHOOL PARTY BE LIABLE TO YOU OR
ANY THIRD PARTY FOR ANY SPECIAL, PUNITIVE, INCIDENTAL, INDIRECT OR
CONSEQUENTIAL DAMAGES OF ANY KIND, OR ANY DAMAGES WHATSOEVER,
INCLUDING, WITHOUT LIMITATION, THOSE RESULTING FROM LOSS OF USE,
DATA OR PROFITS, WHETHER OR NOT WE HAVE BEEN ADVISED OF THE
POSSIBILITY OF SUCH DAMAGES, AND ON ANY THEORY OF LIABILITY, ARISING
OUT OF OR IN CONNECTION WITH THE USE OF THE SERVICE OR OF ANY WEB
SITE REFERENCED OR LINKED TO FROM THE SERVICE.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
FURTHER, NEITHER WE,NOR ANY OTHER UNSCHOOL PARTY, SHALL BE LIABLE IN
ANY WAY FOR ANY CLASS OR OTHER THIRD PARTY GOODS AND SERVICES
OFFERED THROUGH THE SERVICE OR FOR ASSISTANCE IN CONDUCTING
COMMERCIAL TRANSACTIONS WITH OTHER USERS OF THE SITE, INCLUDING
WITHOUT LIMITATION ANY TRANSACTION BETWEEN A STUDENT AND Coach.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
SOME JURISDICTIONS PROHIBIT THE EXCLUSION OR LIMITATION OF LIABILITY
FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATIONS
MAY NOT APPLY TO YOU.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Indemnification
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Upon a request by us, you agree to defend, indemnify, and hold us
and the other Unschool Parties harmless from all liabilities,
claims, and expenses, including attorneys&rsquo; fees, that arise
from your violation of these Terms of Service or any of the other
Governing Documents, or other negligent or wrongful conduct. We
reserve the right to assume the exclusive defense and control of any
matter otherwise subject to indemnification by you, in which event
you will cooperate with us in asserting any available defenses.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<strong>
<u>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Miscellaneous
</span>
</u>
</strong>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You agree that the Service shall be deemed a passive interactive
service based solely in Hyderabad, India and shall not give rise to
personal jurisdiction over Unschool, either specific or general, in
jurisdictions other than Hyderabad, India. These Terms of Service,
together with the Privacy Policy and the other Governing Documents,
shall all be governed and construed in accordance with the internal
laws of the India, without regard to conflicts of law principles.
You agree that any legal action or proceeding between Unschool and
you for any purpose concerning these Terms of Service, the Privacy
Policy, the other Governing Documents or your use of the Service
shall be brought exclusively in a court of competent jurisdiction
sitting in Hyderabad, India.&nbsp;
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Any cause of action or claim you may have with respect to any of the
foregoing matters must be commenced within one (1) year after the
claim or cause of action arises or such claim or cause of action is
barred.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
You and Unschool agree that any dispute or claim arising from or
relating to the Terms shall be finally settled by arbitration, using
the English language, administered by the Arbitration and
Conciliation Act, 1996 and relevant rules then in effect (those
rules are deemed to be incorporated by reference into this section).
Our arbitration will be handled by a sole arbitrator in accordance
with those rules. Judgment on the arbitration award may be entered
in any court that has jurisdiction. Any arbitration under the Terms
will take place on an individual basis: class arbitrations and class
actions are not permitted. Notwithstanding the foregoing, each party
shall not have the right to bring an action in a court of proper
jurisdiction for injunctive or other equitable or conservatory
relief, pending a final decision by the arbitrator.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
These Terms of Service, together with the Privacy Policy and the
other Governing Documents, constitute the entire agreement between
you and Unschool concerning the Service and the services provided by
Unschool. If any provision of any such document is deemed invalid by
a court of competent jurisdiction, the invalidity of such provision
shall not affect the validity of the remaining provisions thereof,
which shall remain in full force and effect. Unschool&apos;s failure
to insist upon or enforce strict performance of any provision of
these Terms of Service or any of the other Governing Documents shall
not be construed as a waiver of any provision or right. Neither the
course of conduct between the parties nor trade practice shall act
to modify any provision of these Terms of Service. Unschool may
assign its rights and duties under these Terms of Service or any of
the other Governing Documents to any party at any time without
notice to you.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Most communication between Unschool and you will be sent and
received electronically. You agree that all electronic communication
between Unschool and you shall satisfy any legal requirements that
such communications be in writing.
</span>
</p>
<p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:justify;'>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
Any rights of Unschool not expressly granted herein are reserved.
</span>
</p> <p style='margin-top:0cm;margin-right:0cm;margin-bottom:10.0pt;margin-left:0cm;line-height:115%;font-size:15px;font-family:"Calibri",sans-serif;text-align:center;'>
<strong>
<span style='font-size:16px;line-height:115%;font-family:"Open Sans", sans-serif;color:#fff;'>
</p>`,
          }}
        ></div>
      </div>
    </Layout>
  );
};

export default TermsOfServicePage;
